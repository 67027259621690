import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import HeroSectionWithImage from "../components/heroSectionWithImage"
import OurValues from "../components/ourValues"
import Benefits from "../components/benefits"
import JobListings from "../components/jobListings"
import FullWidthVideo from "../components/fullWidthVideo"
import FullWidthImage from "../components/fullWidthImage"
import BlurbsAndButtons from "../components/blurbsAndButtons"
import ImageAndBlurb from "../components/imageAndBlurb"
import { standardizePrismicType } from "../utils/standardizePrismicType"

export const query = graphql`
  query CareersQuery {
    prismic {
      allCareerss {
        edges {
          node {
            careers_meta_title
            careers_meta_description
            body {
              ... on PRISMIC_CareersBodyFull_width_image {
                primary {
                  image
                }
              }
              ... on PRISMIC_CareersBodyHero_section {
                primary {
                  hero_description
                  hero_image
                  page_tagline
                  page_title
                }
              }
              ... on PRISMIC_CareersBodyHero_video {
                primary {
                  auto_play
                  hero_video
                  loop
                  sound
                }
              }
              ... on PRISMIC_CareersBodyOur_values {
                fields {
                  value_icon
                  tagline
                  background_type
                }
              }
              ... on PRISMIC_CareersBodyBenefits {
                fields {
                  benefit_column_text
                }
              }
              ... on PRISMIC_CareersBodyThree_column_blurbs {
                fields {
                  blurb
                  button_text
                  category_title
                  link_destination {
                    ... on PRISMIC__ExternalLink {
                      url
                    }
                  }
                }
                primary {
                  introduction
                }
              }
              ... on PRISMIC_CareersBody50___50_image_blurb {
                primary {
                  image_side
                  image
                  blurb
                }
              }
            }
          }
        }
      }
    }
  }
`

const Careers = ({ data }) => {
  const page = data.prismic.allCareerss.edges[0]
  if (!page) return null
  const prismicSections = page.node.body.map((section) =>
    standardizePrismicType(section),
  )
  if (!prismicSections) return null

  const metaTitle =
    page.node.careers_meta_title && page.node.careers_meta_title[0]?.text
  const metaDescription =
    page.node.careers_meta_description &&
    page.node.careers_meta_description[0]?.text

  return (
    <Layout activePage="careers" bottomBorder={true}>
      <SEO metaTitle={metaTitle} metaDescription={metaDescription} />
      <div>
        {prismicSections.map((prismicSection) => {
          let template
          switch (prismicSection.__typename) {
            case "PRISMIC_CareersBodyFull_width_image":
              template = (
                <FullWidthImage
                  src={prismicSection.primary.image.url}
                  alt={prismicSection.primary.image.alt}
                />
              )
              break
            case "PRISMIC_CareersBodyHero_section":
              template = (
                <HeroSectionWithImage heroData={prismicSection.primary} />
              )
              break
            case "PRISMIC_CareersBodyHero_video":
              template = <FullWidthVideo videoData={prismicSection.primary} />
              break
            case "PRISMIC_CareersBodyOur_values":
              template = <OurValues valuesData={prismicSection.fields} />
              break
            case "PRISMIC_CareersBodyBenefits":
              template = <Benefits benefitsData={prismicSection.fields} />
              break
            case "PRISMIC_CareersBodyJobs":
              template = (
                <JobListings
                  benefitsData={prismicSection.fields}
                  headerData={prismicSection.primary}
                />
              )
              break
            case "PRISMIC_CareersBodyThree_column_blurbs":
              template = (
                <BlurbsAndButtons
                  introductionData={prismicSection.primary}
                  blurbsData={prismicSection.fields}
                />
              )
              break
            case "PRISMIC_CareersBody50___50_image_blurb":
              template = <ImageAndBlurb data={prismicSection.primary} />
              break
            default:
              template = null
          }
          return template
        })}
      </div>
    </Layout>
  )
}

export default Careers
