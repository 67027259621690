import React from "react"
import Imgix from "react-imgix"
import styles from "./stylesheets/ourValues.module.scss"
import cx from "classnames"

export default function FullWidthImage({ valuesData }) {
  return (
    <section className="p-3 p-md-5">
      <h4 className="pb-2">Our Values Drive Us</h4>
      <div className="d-flex flex-wrap justify-content-center">
        <div className={styles.valueContainer}>
          {valuesData.slice(0, 2).map((valueData) => (
            <OurValue key={valueData.background_type} valueData={valueData} />
          ))}
        </div>
        <div className={styles.valueContainer}>
          {valuesData.slice(2).map((valueData) => (
            <OurValue key={valueData.background_type} valueData={valueData} />
          ))}
        </div>
      </div>
    </section>
  )
}

function OurValue({ valueData }) {
  let colorClass
  switch (valueData.background_type) {
    case "Black":
      colorClass = styles.black
      break
    case "Green":
      colorClass = styles.green
      break
    case "Blue":
      colorClass = styles.blue
      break
    case "White":
      colorClass = styles.white
      break
    default:
      colorClass = ""
      break
  }

  return (
    <div
      className={cx(
        colorClass,
        styles.ourValue,
        "d-flex justify-content-center align-items-center pl-4 pl-md-0",
      )}
    >
      <Imgix
        src={valueData.value_icon.url}
        ar={1}
        width={400}
        className={styles.valueIcon}
      />
      <span className={cx(styles.tagline, "pl-3")}>
        {valueData.tagline[0] && valueData.tagline[0].text}
      </span>
    </div>
  )
}
