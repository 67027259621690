import React from "react"
import { RichText } from "prismic-reactjs"
import { linkResolver } from "../utils/linkResolver"
import styles from "./stylesheets/blurbsAndButtons.module.scss"
import cx from "classnames"

export default function BlurbsAndButtons({ introductionData, blurbsData }) {
  console.log({ introductionData, blurbsData })

  return (
    <div
      className={
        (cx(styles.topLevelContainer),
        "mb-4 mt-2 mx-4 mx-md-5 d-flex flex-wrap")
      }
    >
      <div className={cx(styles.blurbText, "mb-3")}>
        <RichText
          render={introductionData.introduction}
          linkResolver={linkResolver}
        />
      </div>
      {blurbsData.map((blurbInfo, index) => {
        const title =
          blurbInfo.category_title && blurbInfo.category_title[0]?.text
        const buttonText =
          blurbInfo.button_text && blurbInfo.button_text[0]?.text

        return (
          <div
            key={title}
            className={cx(
              styles.blurbContainer,
              "d-flex flex-column justify-content-between col-12 col-md-6 col-lg-4 pl-0 mb-5",
            )}
          >
            <div className="mb-3">
              <div className={styles.blurbTitle}>{title}</div>
              <div
                className={cx(styles.blurbText, "my-3 mr-lg-4 mr-md-3 mr-2")}
              >
                <RichText
                  render={blurbInfo.blurb}
                  linkResolver={linkResolver}
                />
              </div>
            </div>
            <div className="mr-lg-4 mr-md-3 mr-2">
              <a
                className={cx(
                  styles.applyNowButton,
                  "btn btn-block text-primary",
                )}
                href={blurbInfo.link_destination.url}
                target="_blank"
                rel="noopener noreferrer"
              >
                {buttonText}
              </a>
            </div>
          </div>
        )
      })}
    </div>
  )
}
