import React from "react"
import { RichText } from "prismic-reactjs"
import { linkResolver } from "../utils/linkResolver"
import styles from "./stylesheets/benefits.module.scss"
import cx from "classnames"

export default function Benefits({ benefitsData }) {
  return (
    <section className={cx(styles.topLevelContainer, "p-md-5 my-4")}>
      <div className="d-none d-md-flex flex-md-row flex-column px-0">
        {benefitsData.map((benefit, index) => (
          <div
            className={cx(styles.blurb, "col-12 col-md-6 pb-2 pr-4 pl-0")}
            key={index} // eslint-disable-line react/no-array-index-key
          >
            <RichText
              render={benefit.benefit_column_text}
              linkResolver={linkResolver}
            />
          </div>
        ))}
      </div>
    </section>
  )
}
