import React from "react"
import Imgix from "react-imgix"
import { RichText } from "prismic-reactjs"
import { linkResolver } from "../utils/linkResolver"
import styles from "./stylesheets/imageAndBlurb.module.scss"
import cx from "classnames"

export default function ImageAndBlurb({ data }) {
  const image = data.image
  return (
    <section className={cx(styles.topLevelContainer, "mt-4")}>
      <div
        className={cx(
          "d-flex flex-wrap",
          data.image_side === "Right" && "flex-row-reverse",
        )}
      >
        <div className="col-12 col-md-6 my-md-0 mb-3 px-0">
          <Imgix
            sizes="50vw"
            src={image.url}
            alt={image.alt}
            className="w-100"
          />
        </div>
        <div className="col-12 col-md-6 px-3 px-md-0">
          <div
            className={cx(
              styles.blurbContainer,
              "d-flex flex-column justify-content-center mx-md-5 h-100",
            )}
          >
            <RichText render={data.blurb} linkResolver={linkResolver} />
          </div>
        </div>
      </div>
    </section>
  )
}
