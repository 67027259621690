import React from "react"
import InteractiveMap from "./interactiveMap"
import { Dropdown, Spinner, Button } from "react-bootstrap"
import { OutboundLink } from "gatsby-plugin-google-gtag"
import { useQuery } from "react-query"
import parse from "html-react-parser"
import styles from "./stylesheets/jobListings.module.scss"
import cx from "classnames"

const getJobs = async () => {
  const path = "/api/job_listings"
  const headers = new Headers({
    "mode": "no-cors",
    "Content-Type": "text/xml",
    "access-control-allow-origin": "no-cors",
  })

  const resp = await window.fetch(path, headers)
  const data = await resp.text()
  const xml = new DOMParser().parseFromString(data, "application/xml")
  const jobs = Array.from(xml.getElementsByTagName("Job"))
  return jobs
}

export default function JobListings() {
  const [openJob, setOpenJob] = React.useState(null)
  const [activeListingType, setActiveListingType] = React.useState(
    "All Positions",
  )
  const { status, data } = useQuery(`jobs`, getJobs)

  let activeJobs
  if (data) {
    activeJobs = data.filter((job) => {
      if (activeListingType === "All Positions") return true

      const listingTypeName = job.getElementsByTagName("CompanyName")[0]
        ?.textContent
      return activeListingType === filterName(listingTypeName)
    })
  } else {
    activeJobs = []
  }

  return (
    <div className={styles.topLevelContainer}>
      <section id="jobListings" className="d-flex flex-column py-md-5 pl-md-5">
        <div className="d-none d-md-flex row px-0">
          <div className="col-4 col-xl-3">
            <h2 className={cx(styles.listingsHeader, "my-4")}>
              Current Openings
            </h2>
            <div>
              <Dropdown>
                <Dropdown.Toggle
                  variant="outline-secondary"
                  className={cx(
                    styles.closedLink,
                    "bg-white text-black d-flex align-items-center justify-content-between",
                  )}
                >
                  {activeListingType || "All Positions"}
                </Dropdown.Toggle>
                <Dropdown.Menu className={cx(styles.careerMenuList, "py-0")}>
                  <Dropdown.Item
                    key="all-positions"
                    className={styles.careerMenuItem}
                    onSelect={() => setActiveListingType("All Positions")}
                  >
                    All Positions
                  </Dropdown.Item>
                  <Dropdown.Item
                    key="corporate"
                    className={cx(styles.careerMenuItem)}
                    onSelect={() => setActiveListingType("Management/Support")}
                  >
                    Management/Support
                  </Dropdown.Item>
                  <Dropdown.Item
                    key="drivers"
                    className={cx(styles.careerMenuItem)}
                    onSelect={() =>
                      setActiveListingType("Professional Car Haulers")
                    }
                  >
                    Professional Car Haulers
                  </Dropdown.Item>
                  <Dropdown.Item
                    key="third-party"
                    className={styles.careerMenuItem}
                    onSelect={() => setActiveListingType("Technicians")}
                  >
                    Technicians
                  </Dropdown.Item>
                  <Dropdown.Item
                    key="independent-contractors"
                    className={styles.careerMenuItem}
                    onSelect={() =>
                      setActiveListingType("Independent Contractors")
                    }
                  >
                    Independent Contractors
                  </Dropdown.Item>
                  <Dropdown.Item
                    key="Yard & Shuttle"
                    className={styles.careerMenuItem}
                    onSelect={() => setActiveListingType("Yard & Shuttle")}
                  >
                    Yard & Shuttle
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
          <div
            className={cx(styles.interativeMapPosition, "col-8 col-xl-9 pr-0")}
          >
            <InteractiveMap isCareers={true} />
          </div>
        </div>
        <div className="row justify-content-around my-4 mx-2">
          <div className={"px-2 mx-4 mx-lg-0 w-100"}>
            <div className={cx(styles.jobTableHead, "row py-2")}>
              <div className="col-6 pl-0">Job Title</div>
              <div className="col-3"></div>
              <div className="col-3 pl-0">Locations</div>
            </div>
            {activeJobs.map((job, index) => (
              <JobPost
                job={job}
                isOpen={job === openJob}
                setActiveListingType={setActiveListingType}
                setOpenJob={setOpenJob}
                key={index} // eslint-disable-line react/no-array-index-key
              />
            ))}
            <div
              className={cx(
                styles.emptyJobListingsAlert,
                "d-flex justify-content-center align-items-center py-3 text-secondary",
              )}
            >
              {status === "loading" && <LoadingSpinner />}
              {status === "success" && !activeJobs[0] && (
                <div>There are currently no open positions</div>
              )}
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

function JobPost({ isOpen, setOpenJob, job, setActiveListingType }) {
  const jobTitle = job.getElementsByTagName("JobTitle")[0]?.textContent
  const jobUrl = job.getElementsByTagName("URL")[0]?.textContent
  const jobDescription = job.getElementsByTagName("JobDescription")[0]
    ?.textContent
  const jobRequirements = job.getElementsByTagName("JobRequirements")[0]
    ?.textContent
  const locations = Array.from(job.getElementsByTagName("Location"))
  const locationStates = locations.filter(
    (location) => location.getElementsByTagName("State")[0]?.textContent !== "",
  )
  const states = locationStates.map(
    (s) => s?.getElementsByTagName("State")[0]?.textContent,
  )
  const locationCity = locations.find(
    (location) => location.getElementsByTagName("City")[0]?.textContent !== "",
  )
  const city = locationCity?.getElementsByTagName("City")[0]?.textContent
  const displayCityState = () => {
    if (states.length > 1) {
      return states.join(", ")
    } else if (city && states.length === 1) {
      return `${city.toLowerCase()}, ${states[0]}`
    } else if (!city && states.length === 1) {
      return states[0]
    } else if (city) {
      return city.toLowerCase()
    }
  }
  const toggleJob = (e) => {
    isOpen ? setOpenJob(null) : setOpenJob(job)
    e.target.scrollIntoView()
  }

  return (
    <div className={styles.topLevelContainer}>
      <div className={cx(styles.jobRow, "row py-2 w-100")} key={jobTitle}>
        <div className="col-6 px-0">
          <Button
            className={cx(styles.jobTitle, "bg-white border-0 text-blue px-0")}
            onClick={toggleJob}
          >
            {jobTitle}
          </Button>
        </div>
        <div className="col-3 px-0"></div>
        <div className={cx(styles.capitalize, "col-3 px-0")}>
          {displayCityState()}
        </div>
      </div>
      {isOpen && (
        <div id={jobUrl}>
          <div className="pt-3 pb-2">
            <h4>Description</h4>
            <div class="description">{parse(jobDescription)}</div>
          </div>
          <div>
            <h4>Requirements</h4>
            <div class="requirements">{parse(jobRequirements)}</div>
          </div>
          <div className="my-4">
            <OutboundLink
              href={jobUrl}
              target="_blank"
              rel="noopener noreferrer"
              className={cx(styles.applyButton, "btn btn-success btn-lg")}
            >
              Apply Now
            </OutboundLink>
          </div>
        </div>
      )}
    </div>
  )
}

function filterName(listingTypeName) {
  switch (listingTypeName) {
    case "URS Independent Contractor":
      return "Independent Contractors"
    case "URS Mechanics":
      return "Technicians"
    case "United Road Services (ND)":
      return "Management/Support"
    case "United Road Services":
      return "Professional Car Haulers"
    case "Team Driveaway (ND)":
      return "Yard & Shuttle"
    default:
      return "All Positions"
  }
}

function LoadingSpinner() {
  return (
    <Spinner animation="border" role="status">
      <span className="sr-only">Loading...</span>
    </Spinner>
  )
}
